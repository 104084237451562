import React, { useMemo, useState } from 'react';
import { css } from 'styled-components';
import Image from 'gatsby-image';
import { Download, Instagram, Facebook, Twitter, Youtube } from 'components/.base/Icons';
import { breakpoint } from 'constants/Breakpoint';
import {
    BackgroundDivBgImage,
    CenteredContainer,
    Container,
    FlexBox,
} from 'components/.base/containers';
import { BodyText, Heading, HeadingLarge, Subheading } from 'components/.base/headings';
import { RedButton, WhiteButton } from 'components/.base/buttons';
import {
    Collapsible,
    CollapsibleButton,
    CollapsibleInner,
    CollapsibleItemWrapper,
} from 'components/.base/Collapsible';
import LogoSection from '../LogoSection';
import Video, { ItemWrapper } from 'components/.base/video';
import { WoodenBorder } from 'components/.base/border';
import {
    EventsGrid,
    IconWrapper,
    Member,
    MemberImgWrapper,
    MemberName,
    Members,
    PhotoItem,
    PhotosGrid,
    Section,
    SectionHeading,
    SectionsGrid,
    SocialList,
    Subsection,
    SubsectionHeading,
    VideosGrid,
} from './PressKitSection.styled';
import Modal from 'components/.base/modal';

const PressKitSection = ({ section }) => {
    const {
        appearedAt,
        appearedIn,
        asSeenOn,
        awards,
        companyOverview,
        contactUs,
        download,
        facebook,
        foundedIn,
        founders,
        foundersDescription,
        instagram,
        logoSection,
        ourHeadquarters,
        photos,
        restaurantLocations,
        title,
        truckLocations,
        twitter,
        videos,
        youtube,
    } = section;
    const [modalIndex, setModalIndex] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const validVideos = useMemo(() => videos.filter(item => !!item?.videoLink), [videos]);
    const handlePreviousModal = () => {
        setModalIndex(idx => {
            if (idx === 0 && validVideos.length > 0) {
                return validVideos.length - 1;
            } else {
                return idx - 1;
            }
        });
    };
    const handleNextModal = () => {
        setModalIndex(idx => {
            if (idx === validVideos.length - 1) {
                return 0;
            } else {
                return idx + 1;
            }
        });
    };

    return (
        <div
            css={css`
                padding: 80px 0 0;
                ${BodyText} {
                    font-size: 16px;
                    line-height: 1.38;
                    letter-spacing: -0.2px;
                    white-space: break-spaces;
                    a {
                        color: #d4212c;
                        text-decoration: underline;
                    }
                    ol,
                    ul {
                        padding-left: 1em;
                    }
                }
                ${CollapsibleItemWrapper} {
                    border-bottom: 0;
                }
                ${CollapsibleButton}, ${CollapsibleInner} ${BodyText} {
                    padding: 0;
                }
                ${CollapsibleButton} {
                    margin-bottom: 20px;
                    text-transform: none;
                    ${SubsectionHeading} {
                        margin-bottom: 0;
                    }
                }
            `}
        >
            <Container>
                <div
                    css={css`
                        max-width: 845px;
                        margin: 0 auto;
                    `}
                >
                    <SectionsGrid>
                        <Section>
                            <SectionHeading>
                                The <span className="red">basics</span>
                            </SectionHeading>
                            <Subsection
                                css={css`
                                    display: none;
                                `}
                            >
                                <RedButton
                                    as="a"
                                    href={`${download.file.url}`}
                                    target="_blank"
                                    download
                                >
                                    Download PDf
                                </RedButton>
                            </Subsection>
                            <Subsection>
                                <SubsectionHeading as="h3">
                                    <span className="red">Founded </span>in
                                </SubsectionHeading>
                                <HeadingLarge as="p">{foundedIn}</HeadingLarge>
                            </Subsection>
                            <Subsection>
                                <SubsectionHeading as="h3">
                                    <span className="red">Social </span>media
                                </SubsectionHeading>
                                <SocialList>
                                    {instagram ? (
                                        <li>
                                            <a href={instagram}>
                                                <IconWrapper as="span">
                                                    <Instagram />
                                                </IconWrapper>
                                                <Subheading>@cousinsmainelobster</Subheading>
                                            </a>
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                    {facebook ? (
                                        <li>
                                            <a href={facebook}>
                                                <IconWrapper as="span">
                                                    <Facebook />
                                                </IconWrapper>
                                                <Subheading>@cousinsmainelobster</Subheading>
                                            </a>
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                    {twitter ? (
                                        <li>
                                            <a href={twitter}>
                                                <IconWrapper as="span">
                                                    <Twitter />
                                                </IconWrapper>
                                                <Subheading>@cousinsmainelob</Subheading>
                                            </a>
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                    {youtube ? (
                                        <li>
                                            <a href={youtube}>
                                                <IconWrapper as="span">
                                                    <Youtube />
                                                </IconWrapper>
                                                <Subheading>@cousinsmainelobster</Subheading>
                                            </a>
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                </SocialList>
                            </Subsection>
                            <Subsection>
                                <SubsectionHeading as="h3">
                                    <span className="red">Contact </span>us
                                </SubsectionHeading>
                                <BodyText as="div">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: contactUs.contactUs,
                                        }}
                                    />
                                </BodyText>
                            </Subsection>
                            <Subsection>
                                <SubsectionHeading as="h3">
                                    Our <span className="red">headquarters</span>
                                </SubsectionHeading>
                                <BodyText as="div">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: ourHeadquarters.ourHeadquarters,
                                        }}
                                    />
                                </BodyText>
                            </Subsection>
                            <Subsection>
                                <Collapsible
                                    open={true}
                                    heading={
                                        <SubsectionHeading as="span">
                                            Truck <span className="red">locations</span>
                                        </SubsectionHeading>
                                    }
                                    description={truckLocations.truckLocations}
                                />
                            </Subsection>
                            <Subsection>
                                <Collapsible
                                    open={true}
                                    heading={
                                        <SubsectionHeading as="span">
                                            Restaurant <span className="red">locations</span>
                                        </SubsectionHeading>
                                    }
                                    description={restaurantLocations.restaurantLocations}
                                />
                            </Subsection>
                        </Section>
                        <div>
                            <Section>
                                <SectionHeading>
                                    Company <span className="red">overview</span>
                                </SectionHeading>
                                <BodyText as="div">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: companyOverview.companyOverview,
                                        }}
                                    />
                                </BodyText>
                            </Section>
                            <Section>
                                <SectionHeading>
                                    The <span className="red">founders</span>
                                </SectionHeading>
                                <Members>
                                    {founders.map(({ fluid, title, description }, i) => (
                                        <Member key={i}>
                                            <MemberImgWrapper>
                                                <Image fluid={fluid} alt={description} />
                                            </MemberImgWrapper>
                                            <MemberName as="p">{title}</MemberName>
                                        </Member>
                                    ))}
                                </Members>
                                <BodyText as="div">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: foundersDescription.foundersDescription,
                                        }}
                                    />
                                </BodyText>
                            </Section>
                            <Section>
                                <SectionHeading>
                                    Events <span className="red">& accolades</span>
                                </SectionHeading>
                                <EventsGrid>
                                    <div>
                                        <SubsectionHeading as="h3">
                                            <span className="red">Awards</span>
                                        </SubsectionHeading>
                                        <BodyText as="div">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: awards.awards,
                                                }}
                                            />
                                        </BodyText>
                                    </div>
                                    <div>
                                        <SubsectionHeading as="h3">
                                            <span className="red">Appeared </span>
                                            in
                                        </SubsectionHeading>
                                        <BodyText as="div">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: appearedIn.appearedIn,
                                                }}
                                            />
                                        </BodyText>
                                    </div>
                                    <div>
                                        <SubsectionHeading as="h3">
                                            <span className="red">As seen </span>
                                            on
                                        </SubsectionHeading>
                                        <BodyText as="div">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: asSeenOn.asSeenOn,
                                                }}
                                            />
                                        </BodyText>
                                    </div>
                                    <div>
                                        <SubsectionHeading as="h3">
                                            <span className="red">Appeared </span>
                                            at
                                        </SubsectionHeading>
                                        <BodyText as="div">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: appearedAt.appearedAt,
                                                }}
                                            />
                                        </BodyText>
                                    </div>
                                </EventsGrid>
                            </Section>
                            <LogoSection
                                section={logoSection}
                                styling={`
                                ${CenteredContainer} > div {
                                    padding: 0;
                                }
                            `}
                            />
                        </div>
                    </SectionsGrid>
                </div>
            </Container>
            <div
                css={css`
                    padding: 30px 0;
                    position: relative;
                    @media ${breakpoint.lg} {
                        padding: 100px 0;
                    }
                `}
            >
                <WoodenBorder top="0" />
                <WoodenBorder top="100vh" />
                <BackgroundDivBgImage
                    w="100%"
                    h="100vh"
                    heightMobile="100vh"
                    top="0"
                    topMobile="0"
                    color="#edf2f2"
                    style={{ zIndex: '-2' }}
                />
                <Container>
                    <Heading
                        css={css`
                            text-align: center;
                            margin-bottom: 55px;
                        `}
                    >
                        Videos
                    </Heading>
                    <VideosGrid
                        css={css`
                            ${ItemWrapper} {
                                padding-bottom: 64%;
                                margin-bottom: 0;
                                .gatsby-image-wrapper > div {
                                    padding-bottom: 64% !important;
                                }
                            }
                        `}
                    >
                        {validVideos.map((video, i) => (
                            <>
                                <Video
                                    key={i}
                                    index={i}
                                    video={video}
                                    setModalIsOpen={setModalIsOpen}
                                    setModalIndex={setModalIndex}
                                />
                            </>
                        ))}
                    </VideosGrid>
                </Container>
            </div>
            <div
                css={css`
                    padding: 30px 0;
                    @media ${breakpoint.lg} {
                        padding: 100px 0;
                    }
                `}
            >
                <Heading
                    css={css`
                        text-align: center;
                        margin-bottom: 55px;
                    `}
                >
                    Photos
                </Heading>
                <Container>
                    <PhotosGrid>
                        {photos.map(({ file, fluid, description }, i) => (
                            <PhotoItem key={i}>
                                <Image fluid={fluid} alt={description} />
                                <WhiteButton as="a" href={file.url} target="_blank" download>
                                    <Download />
                                </WhiteButton>
                            </PhotoItem>
                        ))}
                    </PhotosGrid>
                </Container>
            </div>
            <Modal
                open={modalIsOpen}
                onClose={() => setModalIsOpen(false)}
                ariaLabel={validVideos[modalIndex]?.title || 'Video'}
                modalMaxWidth="650px"
                top="0"
            >
                <div
                    css={css`
                        .recipe-modal-header {
                            border-radius: 30px;
                            border-bottom-left-radius: 0px;
                            border-bottom-right-radius: 0px;
                            box-shadow: 0 1px 0 0 #c2cbcc;
                            background-color: #f4f7f7;
                            padding: 24px 30px;
                        }
                        .recipe-modal-body {
                            padding: 35px 30px;
                            min-height: 350px;
                            .icon-reversal-cont {
                                position: absolute;
                                width: 84px;
                                height: 84px;
                                background-color: #d4212c;
                                border-radius: 50%;
                                margin-right: 1rem;
                                flex-grow: 1;
                                svg {
                                    position: absolute;
                                    left: 26px;
                                    top: 26px;
                                    g {
                                        stroke: #fff;
                                    }
                                    &.uses-fill g {
                                        stroke: transparent;
                                        fill: #fff;
                                    }
                                }
                            }
                            iframe {
                                height: 332px;
                            }
                        }
                        .recipe-modal-footer {
                            border-radius: 30px;
                            border-top-left-radius: 0px;
                            border-top-right-radius: 0px;
                            box-shadow: 0 -1px 0 0 #c2cbcc;
                            background-color: #f4f7f7;
                            padding: 24px 30px;
                            button {
                                margin-left: 1rem;
                            }
                        }
                        @media screen and (max-width: 1024px) {
                            height: 100%;
                            .recipe-modal-header {
                                padding-right: 80px;
                            }
                            .recipe-modal-body {
                                height: calc(100% - 104px - 88px);
                                padding: 20px 5px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                            }
                        }
                    `}
                >
                    <div className="recipe-modal-header">
                        <Heading as="h1">{validVideos[modalIndex]?.title || 'Video'}</Heading>
                    </div>
                    <div className="recipe-modal-body">
                        <iframe
                            width="100%"
                            title={validVideos[modalIndex]?.title || 'Video'}
                            src={`${validVideos[modalIndex].videoLink}?autoplay=1`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className="recipe-modal-footer">
                        <FlexBox justify="flex-end">
                            <WhiteButton onClick={handlePreviousModal}>Previous</WhiteButton>
                            <RedButton onClick={handleNextModal}>Next</RedButton>
                        </FlexBox>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default PressKitSection;
