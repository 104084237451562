import styled from 'styled-components';
import { Heading, Subheading } from 'components/.base/headings';
import { RedButton, WhiteButton } from 'components/.base/buttons';
import { breakpoint } from 'constants/Breakpoint';

export const SectionsGrid = styled.div`
    @media ${breakpoint.lg} {
        display: grid;
        grid-gap: 40px;
        grid-template-columns: 36% 64%;
    }
`;
export const Section = styled.div`
    margin-bottom: 80px;
`;

export const SectionHeading = styled(Heading)`
    padding-bottom: 16px;
    border-bottom: 1px solid #e2e4e4;
    margin-bottom: 40px;
`;

export const Subsection = styled.div`
    margin-bottom: 50px;
`;

export const SubsectionHeading = styled(Heading)`
    margin-bottom: 20px;
`;

export const IconWrapper = styled(RedButton)`
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .icon {
        width: 16px;
        height: auto;
        margin: 0;
        stroke: none;
        fill: none;
        path {
            stroke: none;
        }
    }
`;

export const SocialList = styled.ul`
    list-style: none;
    padding-left: 0;
    > li {
        margin-bottom: 10px;
        > a {
            display: flex;
            align-items: center;
        }
        ${IconWrapper} {
            margin-right: 10px;
        }
    }
`;

export const Members = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 32px;
`;
export const Member = styled.div``;

export const MemberImgWrapper = styled.div`
    border-radius: 30px;
    margin-bottom: 22px;
`;

export const MemberName = styled(Subheading)`
    text-align: center;
`;

export const EventsGrid = styled.div`
    display: grid;
    grid-gap: 50px 20px;
    @media ${breakpoint.md} {
        grid-template-columns: 1fr 1fr;
    }
`;

export const VideosGrid = styled.div`
    display: grid;
    grid-gap: 20px;
    @media ${breakpoint.lg} {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const PhotosGrid = styled.div`
    display: grid;
    grid-gap: 20px;
    @media ${breakpoint.md} {
        grid-template-columns: repeat(2, 1fr);
    }
    @media ${breakpoint.lg} {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const PhotoItem = styled.div`
    border-radius: 30px;
    overflow: hidden;
    height: 0;
    padding-bottom: 75%;
    position: relative;
    .gatsby-image-wrapper > div {
        padding-bottom: 75% !important;
    }
    ${WhiteButton} {
        position: absolute;
        right: 20px;
        bottom: 20px;
        height: 40px;
        width: 40px;
        padding: 0;
        .icon {
            stroke: none;
            width: 16px;
            height: auto;
        }
    }
`;
