import React from 'react';
import styled, { keyframes } from 'styled-components';
import Image from 'gatsby-image';

import { WhiteButton } from '~/components/.base/buttons';
import PlayButtonIcon from '~/images/icons/pdp/play-btn.svg';
import { Heading } from './headings';
import { breakpoint } from '../../constants/Breakpoint';

const expandPDP = keyframes`
    from {
        top: 0px;
        left: 0px;
        border: 1px solid #fff;
    }
    50% {
        top: -50px;
        left: -50px;
        width: calc(100% + 100px);
        height: calc(100% + 100px);
        opacity: 0;
    }
    to {
        top: -50px;
        left: -50px;
        width: calc(100% + 100px);
        height: calc(100% + 100px);
        opacity: 0;
    }
`;

export const Item = styled.div`
    display: block;
    ${Heading} {
        line-height: 1.17;
        @media ${breakpoint.maxMd} {
            line-height: 1.25;
            font-size: 16px;
            font-weight: normal;
        }
    }
    img {
        transition: opacity 0.3s linear;
    }
    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
    }
    ${WhiteButton} {
        width: 60px;
        height: 60px;
        position: absolute;
        left: calc(50% - 30px);
        top: calc(50% - 30px);
        padding: 0;
        .animation-1,
        .animation-2 {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: transparent;
            border-radius: 50%;
            z-index: 0;
        }
        &:hover {
            .animation-1,
            .animation-2 {
                animation: ${expandPDP} 3s linear infinite;
            }
            .animation-2 {
                animation-delay: 0.75s;
            }
        }
    }
    &.playing {
        img {
            opacity: 0;
            display: none;
        }
        iframe {
            z-index: 1;
        }
    }
`;

export const ItemWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    margin-bottom: 15px;
    border-radius: 30px;
    overflow: hidden;
    .gatsby-image-wrapper > div {
        padding-bottom: 100% !important;
    }
    @media ${breakpoint.lg} {
        margin-bottom: 27px;
    }
`;

const Video = ({ video, index, setModalIndex, setModalIsOpen }) => {
    const { poster, title } = video;

    return (
        <Item>
            <ItemWrapper>
                <Image fluid={poster.fluid} alt={poster.description} />

                <WhiteButton
                    onClick={() => {
                        setModalIndex(index);
                        setModalIsOpen(true);
                    }}
                >
                    <PlayButtonIcon />
                    <span className="animation-1" />
                    <span className="animation-2" />
                </WhiteButton>
            </ItemWrapper>
            {title ? <Heading>{title}</Heading> : ''}
        </Item>
    );
};

export default Video;
